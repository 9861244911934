.fade-in-top {
    animation: fade-in-top 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


.fade-in-bottom {
    animation: fade-in-bottom 0.2s linear;
}

@keyframes fade-in-bottom {
    0% {
        position: relative;
        top: 2rem;
        opacity: 0.5;
    }

    90% {
        position: relative;
        top: 0rem;
        opacity: 1;
    }

    100% {
        position: none;
    }
}


.height-animation-navbar {
    animation: height-animation-navbar .5s forwards;
}

@keyframes height-animation-navbar {
    0% {
        height: 5rem;
    }
    
    100% {
        height: 10rem;
    }
}

.slide-in-top {
    animation: slide-in-top .5s both;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-1000px);
        opacity: 0; 
    }

    100% {
        transform: translateY(0);
        opacity: 1; 
    }
}