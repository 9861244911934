@tailwind base;
@tailwind components;
@tailwind utilities;
@import './animation.css';

@layer base {
    input {
        @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 outline-none;
    }

    textarea {
        @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm resize-none rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 outline-none;
    }

    button {
        @apply text-white bg-primary hover:bg-blue-800 duration-200 font-medium rounded-lg text-sm py-2.5 me-2 mb-2;
    }

    label {
        @apply block mb-2 text-sm font-medium
    }
}

@layer components {
    .button-2 {
        @apply bg-transparent hover:bg-primary border-2 text-primary-dark hover:text-white duration-200;
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

.shadow-style-2 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.secundary {
    background-color: #0069af;
}

.text-secundary {
    color: #0069af;
}

html,
body {
    color: #27415a;
}

li {
    cursor: pointer;
}